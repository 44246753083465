body {
  font-family: "Maison Neue", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: $raven-grey;
}

h1,
.heading-display {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-size: 10vw;
  line-height: 0.75em;
  letter-spacing: 0.05em;
  margin: 0;
  padding: 0;
}

h2,
.heading-secondary {
  font-family: "Maison Neue", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 1.25em;
  margin-bottom: 1em;
}

h3,
.heading-tertiary {
  font-family: "Maison Neue", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 1em;
  margin-bottom: 1em;
}

p {
  font-size: 1em;
  line-height: 1.75em;
  letter-spacing: 0.01em;
  margin: 1.5em 0;
  font-weight: 400;

  &.small {
    font-size: 0.8em;
  }
}

a {
  font-family: "Maison Neue Mono", "Courier New", monospace;
  position: relative;
  display: inline-block;
  color: $arapawa;
  font-weight: normal;
  line-height: 1.8em;
  font-size: inherit;
  letter-spacing: 0.01em;
  text-decoration: $geyser wavy underline 1px;
  text-underline-offset: 0.4em;
  transition: all ease-in-out 0.2s;
  padding: 0.1em;

  &:hover {
    color: $raven-grey;
    text-decoration-color: $arapawa;
    transition: all ease-in-out 0.3s;
  }
}

ul.link-group {
  list-style: none;
  margin: 0;
  padding: 0;

  & > li {
    margin: 0;
    padding: 1em 0;
  }
}
