body {
  background-color: $white-smoke;
  display: flex;
  justify-content: center;
}

img {
  width: 100%;
  max-width: 100%;
  &.banner-animated {
    max-width: 900px;
  }
  &.face-animated {
    max-width: 200px;
    @include md {
      max-width: 280px;
    }
  }
}

.outer-space {
  padding: 30px;
  @include lg {
    max-width: 1160px;
    padding: 100px 120px;
  }
}

.top-image {
  text-align: center;
}

section {
  margin: 80px 0 160px;

  &.homepage {
    @include lg {
      display: grid;
      grid-template-columns: 1.5fr 1fr;
      grid-template-rows: 0.5fr 1fr;
      gap: 5em 8em;
      grid-template-areas:
        ". ."
        ". .";
    }
    div {
      &.secondary-box {
        border-top: 0 solid transparent;
        margin-top: 4em;
        padding-top: 4em;
        @include lg {
          padding-top: 6em;
          margin-top: 0;
          border-top: 1px solid $geyser;
        }
      }
    }
  }
  &.cats {
    @include lg {
      display: grid;
      grid-template-columns: 1.25fr 1fr;
      gap: 2em 2em;
      grid-template-areas: ". .";
    }
  }
  &.work-with-me {
    padding: 4em;
    border: 1px solid $geyser;
  }
}

.face {
  text-align: center;
}

.work-alert {
  padding: 0.25em 1.5em;
  background-color: $highlight;
}
